<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="10"
          class="d-flex justify-content-center align-items-center mb-2"
        >
          <h1><feather-icon
            class="mr-2"
            icon="ListIcon"
            size="25"
          />Prospetto Ferie</h1>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="text-center"
        >
          <b-button
            variant="primary"
            @click="$bvModal.show('export-modal')"
          >
            Esporta CSV
          </b-button>
        </b-col>
      </b-row>
      <managerjustificationsschedule-list-filters
        :department-filter.sync="departmentFilter"
        :department-options="departmentOptions"
        :sub-department-filter.sync="subDepartmentFilter"
        :sub-department-options="subDepartmentOptions"
        :user-filter.sync="userFilter"
        :user-options="userOptions"
      />
    </b-card>
    <!-- Filters -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- Table Container Card -->

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Cerca..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessun Utente corrispondente trovato"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: ID -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">#{{ data.item.anagrafico_id }}</span>
          </div>
        </template>

        <!-- Column: User -->
        <template #cell(utente)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="50"
                :src="data.item.avatar_file ? mediaUrl + data.item.avatar_file : ''"
                :text="avatarText(data.item.display_name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role_code)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.display_name }}
            </b-link>
            <small class="text-muted">{{ data.item.email }}</small>
          </b-media>
        </template>

        <template #cell(reparto)="data">
          <div
            v-if="data.item.department"
            class="text-nowrap"
          >
            <div class="d-flex align-items-center">
              <b-link>
                #{{ data.item.department.id }} - {{ data.item.department.name }}
              </b-link>
            </div>
          </div>
        </template>

        <template #cell(ore-inizio-mese)="data">
          <div
            v-if="data.item.giorniFerie && data.item.giorniPermesso"
            class="text-nowrap wide-column"
          >
            <div class="d-flex flex-column justify-content-center">
              <p class="mb-0">
                <b>Ferie</b>: {{ data.item.giorniFerie.maturato_days }}
              </p>
              <p class="mb-0">
                <b>Permessi</b>: {{ data.item.giorniPermesso.maturato }}
              </p>
            </div>
          </div>
          <div
            v-else
            class="wide-column"
          />
        </template>

        <template #cell(saldo)="data">
          <div
            v-if="data.item.saldoFerie && data.item.saldoPermessi"
            class="text-nowrap"
          >
            <div class="d-flex flex-column justify-content-center">
              <p class="mb-0">
                <b>Ferie</b>: {{ data.item.saldoFerie }}
              </p>
              <p class="mb-0">
                <b>Permessi</b>: {{ data.item.saldoPermessi }}
              </p>
            </div>
          </div>
        </template>
        <template #cell(calendario)="data">
          <div
            v-if="data.item.calendar"
            class="d-flex calendar"
          >
            <div
              v-for="month in data.item.calendar"
              :key="month.month"
              class="month"
            >
              <div
                v-if="data.item.index === 0"
                class="month-name d-flex justify-content-center align-items-center bg-light"
              >
                <p class="mb-0">
                  {{ month.month }}
                </p>
              </div>
              <div class="d-flex">
                <div
                  v-for="month_day in month.days"
                  :key="month_day.fullday"
                  class="month-days"
                >
                  <div
                    class="month-day d-flex flex-column align-items-center"
                    :class="month_day.holiday ? 'bg-secondary' : null"
                  >
                    <p
                      v-if="data.item.index === 0"
                      class="mb-0"
                    >
                      {{ month_day.dayOfMonth }}
                    </p>
                    <p
                      v-if="data.item.index === 0"
                      class="month-weekday"
                    >
                      {{ month_day.weekday }}
                    </p>
                    <div
                      class="type-cell d-flex justify-content-center align-items-center"
                      :class="!month_day.holiday ? 'bg-' + month_day.color : 'bg-transparent'"
                    >
                      <p
                        class="mb-0"
                      >
                        {{ !month_day.holiday ? month_day.type : '' }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="export-modal"
      title="Esporta Ferie"
      footer-class="d-flex justify-content-end"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Esporta Ferie
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <template>
        <b-row class="mt-2 mb-2">
          <b-col
            cols="12"
            class="mb-1"
          >
            <span>Esporta il prospetto ferie di tutti gli utenti in formato csv</span>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          variant="success"
          @click="exportCsv()"
        >
          Esporta
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BForm,
  BFormSelect,
  BFormGroup,
  BFormDatepicker,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import userStoreModule from '@/views/apps/user/userStoreModule'
import moment from 'moment'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import departmentsStoreModule from '@/views/apps/departments/departmentsStoreModule'
import useManagerjustificationsscheduleList from './useManagerjustificationsscheduleList'
import ManagerjustificationsscheduleListFilters from './ManagerjustificationsscheduleListFilters.vue'
import managerjustificationsscheduleStoreModule from '../managerjustificationsscheduleStoreModule'

export default {
  components: {
    ManagerjustificationsscheduleListFilters,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BForm,
    BFormSelect,
    BFormGroup,
    BFormDatepicker,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const DEPARTMENTS_APP_STORE_MODULE_NAME = 'app-departments'
    const JUSTIFICATIONS_SCHEDULE_MODULE = 'app-justificationsschedule'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, managerjustificationsscheduleStoreModule)
    if (!store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(DEPARTMENTS_APP_STORE_MODULE_NAME, departmentsStoreModule)
    }
    if (!store.hasModule(JUSTIFICATIONS_SCHEDULE_MODULE)) {
      store.registerModule(JUSTIFICATIONS_SCHEDULE_MODULE, managerjustificationsscheduleStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENTS_APP_STORE_MODULE_NAME)
    })

    const mediaUrl = process.env.VUE_APP_MEDIA_URL

    const months = [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ]

    const momentMonth = moment().format('M')
    const currentMonth = momentMonth - 1
    const previousMonth = momentMonth - 1
    const currentDate = moment()
    const initialMonth = currentDate.month() // Ottieni il mese corrente (0-11)
    const initialYear = currentDate.year()
    const fromDay = currentDate.startOf('month').format('YYYY-MM-DD')
    const toDay = currentDate.endOf('month').format('YYYY-MM-DD')
    // const users = []

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleName,
      resolveUserRoleVariant,
      resolveUserRoleIcon,

      departmentOptions,
      departmentFilter,
      subDepartmentOptions,
      subDepartmentFilter,
      userFilter,
      userOptions,
    } = useManagerjustificationsscheduleList()

    return {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      // users,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      months,
      currentMonth,
      previousMonth,
      currentYear: initialYear,
      minDay: null,
      fromDay,
      toDay,
      selectedMonth: initialMonth,
      // Filter
      avatarText,

      // UI
      resolveUserRoleName,
      resolveUserRoleVariant,
      resolveUserRoleIcon,

      mediaUrl,

      departmentOptions,
      departmentFilter,
      subDepartmentOptions,
      subDepartmentFilter,
      userFilter,
      userOptions,
    }
  },
  // computed: {
  //   usersFiltered() {
  //     return this.totalUsers.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
  //   },
  //   usersPaginated() {
  //     return this.usersFiltered.slice(0, this.limit)
  //   },
  //   hasNextPage() {
  //     return this.usersPaginated.length < this.usersFiltered.length
  //   },
  // },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    // selectUserCsv(e) {
    //   this.newExport.user_id = e ? e.value : null
    // },
    exportCsv() {
      store
        .dispatch('app-justificationsschedule/exportCsv', {
          sort_by: 'created_at',
          sort_direction: 'DESC',
        })
        .then(response => {
          const now = moment().format('DD-MM-YYYY_HH-mm-ss')
          const blob = new Blob([response.data], {
            type: 'text/csv',
          })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${now}_prospetto_ferie.csv`
          link.click()
          this.$bvModal.hide('export-modal')
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            router.replace({ name: 'auth-login' }).then(() => {
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Sessione scaduta',
                  icon: 'AlertTriangleIcon',
                  text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
                  variant: 'danger',
                },
              })
            })
          }
        })
    },
  },
  // watch: {
  //   userFilter() {
  //     console.log('watch user')
  //   },
  // },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.b-table {
  tr {
    padding-top: 0!important;
    padding-bottom: 0!important;
  }
  td {
    div.wide-column{
      min-width: 140px;
      max-width: 200px;
      word-wrap: break-word;
    }
  }
}
.calendar {
  background-color: white;
  color: black;
  .month {
    border-left: 2px solid grey;
    border-right: 2px solid grey;
    .month-name {
      height: 50px;
      p {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .month-day {
      font-weight: bold;
      width: 40px;
      padding-top: 10px;
      padding-bottom: 10px;
      .month-weekday {
        margin-bottom: 10px;
      }
      .type-cell {
        width: 30px;
        height: 30px;
        border-radius: 5px;
      }
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
