<template>
  <b-row class="mb-2">
    <b-col
      cols="12"
      md="6"
    >
      <label>Reparto</label>
      <v-select
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :value="departmentFilter"
        :options="departmentOptions"
        class="w-100"
        :reduce="(val) => val.value"
        @input="(val) => $emit('update:departmentFilter', val)"
      />
    </b-col>
    <b-col
      cols="12"
      md="6"
      v-if="subDepartmentOptions && subDepartmentOptions.length"
    >
      <label>Sottoreparto</label>
      <v-select
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :value="subDepartmentFilter"
        :options="subDepartmentOptions"
        class="w-100"
        :reduce="(val) => val.value"
        @input="(val) => $emit('update:subDepartmentFilter', val)"
      />
    </b-col>
    <b-col
      v-if="departmentFilter"
      cols="12"
      md="6"
      class="text-center"
    >
      <label>Utente</label>
      <v-select
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :value="userFilter"
        :options="userOptions"
        class="w-100"
        :reduce="(val) => val.value"
        @input="(val) => $emit('update:userFilter', val)"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
  },
  props: {
    departmentFilter: {
      type: [Number, null],
      default: null,
    },
    departmentOptions: {
      type: Array,
      required: true,
    },
    subDepartmentFilter: {
      type: [Number, null],
      default: null,
    },
    subDepartmentOptions: {
      type: Array,
      required: true,
    },
    userFilter: {
      type: [Number, null],
      default: null,
    },
    userOptions: {
      type: Array,
      required: false,
      default: null,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
